import React from "react";
import {Col, Row} from "antd";

import applicationLogo from "../images/stadlalogo.svg";

import {InjectedIntlProps, injectIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";

const translationMessages = defineMessages(
    {
        header_title: {
            id: "header.header_title",
            defaultMessage: "Business Validation Tool" // Sannreyning EN 16931
        },
        header_title_small: {
            id: "header.header_title_small",
            defaultMessage: "ÍST EN 16931"
        },
        header_subtitle: {
            id: "header.header_subtitle",
            defaultMessage: "Validation of electronic invoice and credit note according to TS 236:2018 Rafrænt reikningaferli"
        },
    });

const StadlarHeader: React.FC<InjectedIntlProps> = (props) => {
    document.title = props.intl.formatMessage(translationMessages.header_title) + ' ' + props.intl.formatMessage(translationMessages.header_title_small);
    return (<div className={"header"}>
        <Row type="flex" align="middle" className={"headerRow"}>
            <Col span={16}>
                <div>
                    <span style={{textAlign: "left", fontWeight: 700, fontSize: "1.5em"}}>{props.intl.formatMessage(translationMessages.header_title)}</span>
                    <span style={{marginLeft: "1em", textAlign: "left", fontWeight: 700, fontSize: "1em"}}>{props.intl.formatMessage(translationMessages.header_title_small)}</span>
                </div>
                <div>
                    <span style={{textAlign: "left", fontSize: "smaller"}}>{props.intl.formatMessage(translationMessages.header_subtitle)}</span>
                </div>
            </Col>
            <Col span={8} className={"header_right"}>
                <img src={applicationLogo} alt={"Islenskir stadlar"} height={"45em"}/>
            </Col>
        </Row>
    </div>);
};

export default injectIntl(StadlarHeader);
