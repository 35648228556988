import * as React from "react";
import {Route, Switch} from "react-router-dom";

import ValidateDocument from "./components/ValidateDocument";

export const routes = <div style={{height: "100%"}}>
    <Switch>
        <Route path="/" component={ValidateDocument}/>
    </Switch>
</div>;
