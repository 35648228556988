import Cookies from "js-cookie";
import {addLocaleData} from "react-intl";

import en from "react-intl/locale-data/en";
import is from "react-intl/locale-data/is";

import localeData from "../translations/locales";
import ConfigurationUtility from "./configurationUtility";

const defaultLanguage = ConfigurationUtility.getConfigurationVariableWithLocalhostFallback("default-ui-language"); //"en"

let languageTemp: string = defaultLanguage;//"en";

if (process.env.BABEL_ENV !== "test") {
    languageTemp = Cookies.get("locale") || defaultLanguage;//"en";
    addLocaleData([...en, ...is]);
}

// console.log("localeData =", localeData);

export const language = languageTemp;
export const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code, fallback to defaultLanguage
// @ts-ignore

// console.log("language = ",language);

export const messages = localeData[language] || localeData[languageWithoutRegionCode] || localeData[defaultLanguage];


// console.log("messages = ",messages);

export default class LocaleUtility {
    constructor() {
        if (process.env.BABEL_ENV !== "test") {
            addLocaleData([...en, ...is]);
        }
    };

    static getLangStringWithFallback(lang: string): string {
        if (lang === defaultLanguage) {
            return lang;
        } else {
            return `${lang},${defaultLanguage}`;
        }
    };

    static getLocaleFromCookieOrDefault = () => {
        let languageTemp: string = defaultLanguage;
        if (process.env.BABEL_ENV !== "test") {
            languageTemp = Cookies.get("locale") || defaultLanguage;
        }
        return languageTemp;
    };

    private static localeWithoutRegionCode = (locale: string) => locale.toLowerCase().split(/[_-]+/)[0];

    static getTranslationMessages = (locale: string) => {
        const localeWithoutRegionCode = LocaleUtility.localeWithoutRegionCode(locale);
        // @ts-ignore
        return localeData[locale] || localeData[localeWithoutRegionCode] || localeData[defaultLanguage];
    };

    static setLocaleCookie = (locale: string) => {
        Cookies.set("locale", locale);
    }
}
