export default class ReduxUtility {
    static updateAtIndex = <T>(arrayToUpdate: Array<T>, index, updatedValue: T): Array<T> => {
        const updatedArray = [...arrayToUpdate];
        updatedArray.splice(index, 1, updatedValue);
        return updatedArray;
    };

    static findIndexOfObject = <T, K extends keyof T>(arrayOfObjects: Array<T>, key: K, value: T[K]): number => {
        const newArray = [...arrayOfObjects];
        const index = newArray.findIndex(x => x[key] === value);
        return index;
    }
}
