import React from "react";
import {Badge, Icon, Spin, Typography} from "antd";
import {ValidationOutputWithDocumentName} from "../redux/actions/validations";
import {ValidationLineOutputTypeEnum} from "../apiClients/tapi";
import {IHeaderTranslations} from "../components/ValidateDocument/ValidationList";


export interface IErrorsAndWarningsInfo {
    isDocumentValid: boolean;
    hasSchemaErrors: boolean;
    hasSchemaWarnings: boolean;
    hasSchematronErrors: boolean;
    hasSchematronWarnings: boolean;
    numberOfSchemaErrors: number;
    numberOfSchemaWarnings: number;
    numberOfSchematronErrors: number;
    numberOfSchematronWarnings: number;
}

export default class ValidationUtility {
    static removeResults = (index: number, removeValidationResult: (index: number) => void): React.ReactNode => {
        return (
            <Icon
                style={{color: "red"}}
                type="delete"
                onClick={event => {
                    event.stopPropagation();
                    removeValidationResult(index)
                }}
            />
        )
    };

    static getStatusBadge = (documentValidation: ValidationOutputWithDocumentName): React.ReactNode => {
        let status: "success" | "processing" | "default" | "error" | "warning" = "default";

        if (documentValidation.isValidating) {
            status = "processing";
        } else {
            const errorsAndWarningsInfo = documentValidation.validationSummary;
            if (errorsAndWarningsInfo) {
                if (errorsAndWarningsInfo.hasSchemaWarnings || errorsAndWarningsInfo.hasSchematronWarnings) {
                    status = "warning";
                }
                if (errorsAndWarningsInfo.hasSchemaErrors || errorsAndWarningsInfo.hasSchematronErrors) {
                    status = "error";
                }
                if (errorsAndWarningsInfo.isDocumentValid) {
                    status = "success";
                }
            } else {
                status = "error";
            }

        }
        return <Badge className={"customBadgeStatusDots"} status={status}/>
    };

    static getHeader = (documentValidation: ValidationOutputWithDocumentName, headerTranslations: IHeaderTranslations): React.ReactNode => {

        return (<>
                <div style={
                    {
                        display: "inline-flex",
                        flexFlow: "row nowrap",
                        justifyContent: "space-between",
                        alignItems: "top",
                        width: "97%"
                    }
                }>
                    <div style={{minWidth: "20%"}}>
                        {ValidationUtility.getStatusBadge(documentValidation)}
                        {documentValidation.documentName}
                        <Spin spinning={documentValidation.isValidating} size={"small"} style={{paddingLeft: "1em"}}/>
                    </div>
                    <div style={{maxWidth: "80%"}}>
                        {!documentValidation.isValidating && ValidationUtility.getValidationSummary(documentValidation, headerTranslations)}
                    </div>
                </div>
            </>
        );
    };

    static getErrorsAndWarningsInfo = (documentValidation: ValidationOutputWithDocumentName): IErrorsAndWarningsInfo => {
        let errorsAndWarningsInfo: IErrorsAndWarningsInfo = {
            isDocumentValid: false,
            hasSchemaErrors: false,
            hasSchemaWarnings: false,
            hasSchematronErrors: false,
            hasSchematronWarnings: false,
            numberOfSchemaErrors: 0,
            numberOfSchemaWarnings: 0,
            numberOfSchematronErrors: 0,
            numberOfSchematronWarnings: 0
        };

        if (documentValidation.validationLines) {
            for (let i = 0; i < documentValidation.validationLines.length; i++) {
                switch (documentValidation.validationLines[i].type) {
                    case ValidationLineOutputTypeEnum.SchemaError:
                        errorsAndWarningsInfo.numberOfSchemaErrors++;
                        break;
                    case ValidationLineOutputTypeEnum.SchemaWarning:
                        errorsAndWarningsInfo.numberOfSchemaWarnings++;
                        break;
                    case ValidationLineOutputTypeEnum.SchematronError:
                        errorsAndWarningsInfo.numberOfSchematronErrors++;
                        break;
                    case ValidationLineOutputTypeEnum.SchematronWarning:
                        errorsAndWarningsInfo.numberOfSchematronWarnings++;
                        break;
                }
            }
        }

        errorsAndWarningsInfo.hasSchemaErrors = errorsAndWarningsInfo.numberOfSchemaErrors > 0;
        errorsAndWarningsInfo.hasSchemaWarnings = errorsAndWarningsInfo.numberOfSchemaWarnings > 0;
        errorsAndWarningsInfo.hasSchematronErrors = errorsAndWarningsInfo.numberOfSchematronErrors > 0;
        errorsAndWarningsInfo.hasSchematronWarnings = errorsAndWarningsInfo.numberOfSchematronWarnings > 0;

        errorsAndWarningsInfo.isDocumentValid = !errorsAndWarningsInfo.hasSchemaErrors && !errorsAndWarningsInfo.hasSchemaWarnings && !errorsAndWarningsInfo.hasSchematronErrors && !errorsAndWarningsInfo.hasSchematronWarnings;

        return errorsAndWarningsInfo;
    };

    static getValidationSummary = (documentValidation: ValidationOutputWithDocumentName, headerTranslations: IHeaderTranslations): React.ReactNode => {
        const errorsAndWarningsInfo = documentValidation.validationSummary;
        if (errorsAndWarningsInfo) {
            if ([errorsAndWarningsInfo.numberOfSchemaErrors, errorsAndWarningsInfo.numberOfSchemaWarnings, errorsAndWarningsInfo.numberOfSchematronErrors, errorsAndWarningsInfo.numberOfSchematronWarnings].every((number) => number === 0)) {
                return (<span style={{textAlign: "right"}}>{documentValidation.transactionKey} - {headerTranslations.validDocument}</span>);
            }

            const schemaErrorsMessage = errorsAndWarningsInfo.hasSchemaErrors ? `${errorsAndWarningsInfo.numberOfSchemaErrors} ${headerTranslations.schemaError}, ` : "";
            const schemaWarningsMessage = errorsAndWarningsInfo.hasSchemaWarnings ? `${errorsAndWarningsInfo.numberOfSchemaWarnings} ${headerTranslations.schemaWarning}, ` : "";
            const schematronErrorsMessage = errorsAndWarningsInfo.hasSchematronErrors ? `${errorsAndWarningsInfo.numberOfSchematronErrors} ${headerTranslations.schematronError}, ` : "";
            const schematronWarningsMessage = errorsAndWarningsInfo.hasSchematronWarnings ? `${errorsAndWarningsInfo.numberOfSchematronWarnings} ${headerTranslations.schematronWarning}, ` : "";

            return (<span style={{textAlign: "right"}}>{documentValidation.transactionKey} - {schemaErrorsMessage}{schemaWarningsMessage}{schematronErrorsMessage}{schematronWarningsMessage}</span>);
        } else {
            if (documentValidation.errorMessage.hasOwnProperty("status")) {
                if (documentValidation.errorMessage.status === 422) {
                    return (
                        <Typography.Paragraph ellipsis={{expandable: true,}} style={{marginBottom: "unset", color: "rgba(0, 0, 0, 0.25)"}}>
                            {documentValidation.errorMessage.data.message}
                        </Typography.Paragraph>
                    );
                }
            }
            return `${headerTranslations.errorValidating}`
        }

    };

    static getValidationIcon = (validationLineOutput): React.ReactNode => {
        switch (validationLineOutput.type) {
            case ValidationLineOutputTypeEnum.SchemaWarning:
            case ValidationLineOutputTypeEnum.SchematronWarning:
                return <Icon type="warning" theme="filled" className={"warning"}/>;
            case ValidationLineOutputTypeEnum.SchematronError:
            case ValidationLineOutputTypeEnum.SchemaError:
                return <Icon type="exclamation-circle" theme="filled" className={"error"}/>;
            default:
                return null;
        }
    };
}
