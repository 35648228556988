import React, {useEffect} from "react";
import {Drawer} from "antd";
import {ValidationOutputWithDocumentName} from "../redux/actions/validations";
import {PrismAsync as SyntaxHighlighter} from "react-syntax-highlighter";
import {prism as prismStyle} from "react-syntax-highlighter/dist/esm/styles/prism"
import {getLineNumberFromXmlStringByXpath} from "../utils/xmlUtility";
// import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco"

interface Props {
    visible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    validationOutputWithDocumentName: ValidationOutputWithDocumentName;
    errorLineNumbers: number[];
}

export const DetailsDrawer: React.FC<Props> = (props) => {
    useEffect(() => {
        const element = document.getElementById("scrollToLine");
        if (element) {
            element.scrollIntoView({block: "center", inline: "start", behavior: "smooth"});
        }
    })
    return (
        <Drawer
            getContainer={false}
            width={768}
            placement="right"
            visible={props.visible}
            onClose={() => props.setDrawerVisible(false)}
        >
            {
                props.validationOutputWithDocumentName && props.validationOutputWithDocumentName.fileText
                    ? <SyntaxHighlighter
                        language={'markup'}
                        style={prismStyle}
                        wrapLines
                        showLineNumbers
                        lineProps={(lineNumber) => {
                            let style: React.CSSProperties = {};
                            if (props.errorLineNumbers && props.errorLineNumbers.includes(lineNumber)) {
                                style.backgroundColor = '#FFC8C8';
                            }
                            const lineProps: lineTagPropsFunction | React.HTMLProps<HTMLElement> = {
                                id: props.errorLineNumbers && props.errorLineNumbers.includes(lineNumber) ? "scrollToLine" : undefined,
                                style
                            }
                            return lineProps;
                        }}
                    >
                        {props.validationOutputWithDocumentName && props.validationOutputWithDocumentName.fileText}
                    </SyntaxHighlighter>
                    : null
            }
        </Drawer>
    );
};
