import React from 'react';
import {ValidationLineDetails} from "../../../../../apiClients/tapi";

import {InjectedIntlProps, injectIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";
import {Button} from "antd";

interface SchemaDetailsOwnProps extends InjectedIntlProps {
    validationLineDetails: ValidationLineDetails;
    setLineNumbers: (recordDetail: ValidationLineDetails) => void;
    // setDrawerVisible: (drawerVisible: boolean) => void;
    // setHighlightLineNumber: (highlightLineNumber: number) => void;
}

const translationMessages = defineMessages(
    {
        validation_labelValidationLineLine: {
            id: "schemaDetails.validation_labelValidationLineLine",
            defaultMessage: "Line"
        },
        validation_labelValidationLineColumn: {
            id: "schemaDetails.validation_labelValidationLineColumn",
            defaultMessage: "Column"
        },
    }
);

const SchemaDetails: React.FunctionComponent<SchemaDetailsOwnProps> = (props) => {
    // const handleOpenDetailsDrawer = (lineNumber: number) => {
    //     props.setHighlightLineNumber(lineNumber);
    //     props.setDrawerVisible(true);
    // };
    return (
        <>
            <span>
                <Button
                    onClick={() => props.setLineNumbers(props.validationLineDetails)}
                    type={"link"}
                    className={"wrap_link_btn"}
                >
                    {props.validationLineDetails.description} - {props.intl.formatMessage(translationMessages.validation_labelValidationLineLine)}: {props.validationLineDetails.line} - {props.intl.formatMessage(translationMessages.validation_labelValidationLineColumn)}: {props.validationLineDetails.column}
                </Button>
            </span>
            {/*<Button onClick={() => handleOpenDetailsDrawer(props.validationLineDetails.line)}>asd</Button>*/}
        </>
    );
};

export default injectIntl(SchemaDetails);
