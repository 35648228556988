import React from 'react';
import {Collapse} from "antd";
import ValidationResults from "./ValidationList/ValidationResults";
import {ValidationOutputWithDocumentName} from "../../redux/actions/validations";
import ValidationUtility from "../../utils/validationUtility";

import { InjectedIntlProps, injectIntl } from "react-intl";
import { defineMessages } from "react-intl.macro";
import {DetailsDrawer} from "../DetailsDrawer";

interface IValidationListOwnProps extends InjectedIntlProps {
    validationOutput: Array<ValidationOutputWithDocumentName>;
    removeValidationResult: (index: number) => void;
    activeKey: string;
    setActiveKey: (key: string | Array<string>) => void;
    setDrawerVisible: (drawerVisible: boolean) => void;
    setHighlightLineNumber: (highlightLineNumber: number[]) => void;
    validationOutputWithDocumentName: ValidationOutputWithDocumentName;
}

const translationMessages = defineMessages(
    {
        validation_labelHeaderValidDocument: {
            id: "validationList.validation_labelHeaderValidDocument",
            defaultMessage: "valid document"
        },
        validation_labelHeaderSchemaError: {
            id: "validationList.validation_labelHeaderSchemaError",
            defaultMessage: "XML schema {numberOfSchemaErrors, plural, zero {errors} one {error} other {errors}}"
        },
        validation_labelHeaderSchemaWarning: {
            id: "validationList.validation_labelHeaderSchemaWarning",
            defaultMessage: "XML schema {numberOfSchemaWarnings, plural, zero {warnings} one {warning} other {warnings}}"
        },
        validation_labelHeaderSchematronError: {
            id: "validationList.validation_labelHeaderSchematronError",
            defaultMessage: "schematron {numberOfSchematronErrors, plural, zero {errors} one {error} other {errors}}"
        },
        validation_labelHeaderSchematronWarning: {
            id: "validationList.validation_labelHeaderSchematronWarning",
            defaultMessage: "schematron {numberOfSchematronWarnings, plural, zero {warnings} one {warning} other {warnings}}"
        },
        validation_labelHeaderErrorValidating: {
            id: "validationList.validation_labelHeaderErrorValidating",
            defaultMessage: "Could not validate a document (Please check whether you uploaded a valid XML)"
        },
    }
);

export interface IHeaderTranslations {
    validDocument: string;
    schemaError: string;
    schemaWarning: string;
    schematronError: string;
    schematronWarning: string;
    errorValidating: string;
}

const ValidationList: React.FunctionComponent<IValidationListOwnProps> = (props) => {
    const {validationOutput, removeValidationResult, activeKey, setActiveKey} = props;


    return (
        <Collapse
            className={"container"}
            accordion
            bordered={true}
            activeKey={activeKey}
            onChange={setActiveKey}
        >
            {validationOutput.map((documentValidation, index) => {
                const errorsAndWarningsInfo = ValidationUtility.getErrorsAndWarningsInfo(documentValidation);
                const headerTranslations: IHeaderTranslations = {
                    validDocument: props.intl.formatMessage(translationMessages.validation_labelHeaderValidDocument),
                    schemaError: props.intl.formatMessage(translationMessages.validation_labelHeaderSchemaError, {numberOfSchemaErrors: errorsAndWarningsInfo.numberOfSchemaErrors}),
                    schemaWarning: props.intl.formatMessage(translationMessages.validation_labelHeaderSchemaWarning, {numberOfSchemaWarnings: errorsAndWarningsInfo.numberOfSchemaWarnings}),
                    schematronError: props.intl.formatMessage(translationMessages.validation_labelHeaderSchematronError, {numberOfSchematronErrors: errorsAndWarningsInfo.numberOfSchematronErrors}),
                    schematronWarning: props.intl.formatMessage(translationMessages.validation_labelHeaderSchematronWarning, {numberOfSchematronWarnings: errorsAndWarningsInfo.numberOfSchematronWarnings}),
                    errorValidating: props.intl.formatMessage(translationMessages.validation_labelHeaderErrorValidating),
                };
                return (
                    <Collapse.Panel
                        header={ValidationUtility.getHeader(documentValidation, headerTranslations)}
                        key={documentValidation.guid}
                        extra={ValidationUtility.removeResults(index, removeValidationResult)}
                        disabled={documentValidation.disabled}
                    >
                        <ValidationResults
                            key={index}
                            documentValidation={documentValidation}
                            setDrawerVisible={props.setDrawerVisible}
                            setHighlightLineNumber={props.setHighlightLineNumber}
                            validationOutputWithDocumentName={props.validationOutputWithDocumentName}
                        />
                    </Collapse.Panel>
                )
            })}
        </Collapse>
    );
};

export default injectIntl(ValidationList);
