import {applyMiddleware, createStore, Middleware, Store} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";

import rootReducer from "./redux/reducers/rootReducer";

import {IValidationsState} from "./redux/reducers/validations";
import {IConfigurationState} from "./redux/reducers/configuration";

export interface ApplicationState {
    readonly validations: IValidationsState,
    readonly configuration: IConfigurationState,
}

const middleware: Array<Middleware> = [
    thunk,
];

export default function configureStore(): Store<ApplicationState> {
    return createStore(rootReducer, {}, composeWithDevTools(
        applyMiddleware(...middleware))
    );
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
