import {RcFile} from "antd/es/upload/interface";

const convertRcFileToBase64 = (file: RcFile): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;
            if (typeof base64data === "string") {
                resolve(base64data)
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
};

const convertRcFileToText = (file: RcFile): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const text = reader.result
            if (typeof text === "string") {
                resolve(text);
            }
        };
        reader.onerror = reject;
        reader.readAsText(file);
    })
};

export {convertRcFileToBase64, convertRcFileToText}
