import {Action} from "redux";
import LocaleUtility from "../../utils/localeUtility";

export enum ConfigurationsActionTypesEnum {
    SET_LANGUAGE = "SET_LANGUAGE",
    UNSET_LANGUAGE = "UNSET_LANGUAGE",
}

interface ISetLanguage extends Action<ConfigurationsActionTypesEnum.SET_LANGUAGE> {
    language: string;
}

interface IUnsetLanguage extends Action<ConfigurationsActionTypesEnum.UNSET_LANGUAGE> {
    language: string;
}

export type KnownAction = ISetLanguage | IUnsetLanguage;

export const actionCreators = {
    setLanguage: (language: string) => {
        LocaleUtility.setLocaleCookie(language);
        return <ISetLanguage>{
            type: ConfigurationsActionTypesEnum.SET_LANGUAGE,
            language
        }
    },
};
