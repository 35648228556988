import * as React from "react";

declare global {
    interface Window {
        env: ConfigurationVariables;
        defaultEnv: ConfigurationVariables;
    }
}

export interface ConfigurationVariables {
    // "idsrv-url": string;
    // "wapi-url": string;
    // "cct-url": string;
    // "mapi2-url": string;
    // "rapi-url": string;
    // "documentInfoBaseUrl": string;
    // "deploymentEnvironment": string;
    // googleMapsApiKey: string;
    // registrationEmail: string;
    // islykillLoginUrl: string;
    "tapi-url": string;
    "default-ui-language": string;
    "branding": "unimaze" | "stadlar";
}

class ConfigurationUtility {
    static getConfigurationVariableWithLocalhostFallback(configurationVariableName: keyof ConfigurationVariables): string {
        const configVariable = window.env[configurationVariableName];
        if(configVariable.startsWith("#")) {
            return window.defaultEnv[configurationVariableName];
        } else {
            return configVariable;
        }
    }
}

export default ConfigurationUtility;
