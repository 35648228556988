import React from 'react';
import { Col, Row } from "antd";

import { InjectedIntlProps, injectIntl } from "react-intl";
import { defineMessages } from "react-intl.macro";

import { ValidationOutputWithDocumentName } from "../../../redux/actions/validations";
import ValidationResultsList from "./ValidationResults/ValidationResultsList";
import TableListWithLabel, {TableListWithLabelType} from "../../Common/TableListWithLabel";

interface ValidationResultsOwnProps extends InjectedIntlProps {
    documentValidation: ValidationOutputWithDocumentName;
    setDrawerVisible: (drawerVisible: boolean) => void;
    setHighlightLineNumber: (highlightLineNumber: number[]) => void;
    validationOutputWithDocumentName: ValidationOutputWithDocumentName;
}

const translationMessages = defineMessages(
    {
        validation_documentIsValid: {
            id: "validationResults.validation_documentIsValid",
            defaultMessage: "Document is valid"
        },
        validation_infoLabelProfile: {
            id: "validationResults.validation_infoLabelProfile",
            defaultMessage: "Profile"
        },
        validation_infoLabelCustomizationId: {
            id: "validationResults.validation_infoLabelCustomizationId",
            defaultMessage: "Customization"
        },
        validation_infoLabelDocument: {
            id: "validationResults.validation_infoLabelDocument",
            defaultMessage: "Document"
        }
    }
);

const ValidationResults: React.FunctionComponent<ValidationResultsOwnProps> = (props) => {
    const { documentValidation } = props;

    const spanPropsLabel = {
        xs: 24,
        md: 4,
        xl: 3,
        xxl: 2
    };
    const spanPropsDetails = {
        xs: 24,
        md: 20,
        xl: 21,
        xxl: 22
    };

    const validationResultInfo: TableListWithLabelType[] = [
        {
            label: props.intl.formatMessage(translationMessages.validation_infoLabelProfile),
            value: documentValidation.profileId
        },
        {
            label: props.intl.formatMessage(translationMessages.validation_infoLabelCustomizationId),
            value: documentValidation.customizationId
        },
        {
            label: props.intl.formatMessage(translationMessages.validation_infoLabelDocument),
            value: documentValidation.documentId
        },
    ]


    return (
        <>
            {props.documentValidation && props.documentValidation.validationSummary &&
            props.documentValidation.validationSummary.isDocumentValid && <p className={"validText"}>{props.intl.formatMessage(translationMessages.validation_documentIsValid)}</p>}
            <TableListWithLabel data={validationResultInfo} />
            {props.documentValidation && props.documentValidation.validationSummary &&
                !props.documentValidation.validationSummary.isDocumentValid &&
                <Row>
                    <ValidationResultsList
                        validationLineOutput={props.documentValidation.validationLines}
                        setDrawerVisible={props.setDrawerVisible}
                        setHighlightLineNumber={props.setHighlightLineNumber}
                        validationOutputWithDocumentName={props.validationOutputWithDocumentName}
                    />
                </Row>
            }
        </>
    );
};

export default injectIntl(ValidationResults);
