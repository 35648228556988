import React from 'react';
import {Row, Col, Button} from "antd";
import {ValidationLineDetails} from "../../../../../apiClients/tapi";
import {ColProps} from "antd/es/col";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";

interface SchematronDetailsOwnProps extends InjectedIntlProps {
    validationLineDetails: ValidationLineDetails;
    setLineNumbers: (recordDetail: ValidationLineDetails) => void;
    // setDrawerVisible: (drawerVisible: boolean) => void;
    // setHighlightLineNumber: (highlightLineNumber: number) => void;
}

const translationMessages = defineMessages(
    {
        validation_labelColumnLocation: {
            id: "schematronDetails.validation_labelColumnLocation",
            defaultMessage: "Location"
        },
        validation_labelColumnPattern: {
            id: "schematronDetails.validation_labelColumnPattern",
            defaultMessage: "Pattern"
        },
        validation_labelColumnXPath: {
            id: "schematronDetails.validation_labelColumnXPath",
            defaultMessage: "XPath"
        },
    }
);

const labelColumnProps: ColProps = {
    xs: 24,
    sm: 5,
    md: 4,
    xl: 3,
    xxl: 2,
    className: "schematronDetailsLabels",
};
const valueColumnProps: ColProps = {
    xs: 24,
    sm: 19,
    md: 20,
    xl: 21,
    xxl: 22
};

const SchematronDetails: React.FunctionComponent<SchematronDetailsOwnProps> = (props) => {
    // const handleOpenDetailsDrawer = (lineNumber: number) => {
    //     props.setHighlightLineNumber(lineNumber);
    //     props.setDrawerVisible(true);
    // };
    return (
        <>
            <Row className={"schematronDescription"}>
                <Col span={24}>
                    {props.validationLineDetails.description}
                </Col>
            </Row>
            <Row className={"schematronDetails"}>
                <Col {...labelColumnProps}>
                    {props.intl.formatMessage(translationMessages.validation_labelColumnLocation)}:
                </Col>
                <Col {...valueColumnProps}>
                    {props.validationLineDetails.context}
                </Col>
            </Row>
            <Row className={"schematronDetails"}>
                <Col {...labelColumnProps}>
                    {props.intl.formatMessage(translationMessages.validation_labelColumnPattern)}:
                </Col>
                <Col {...valueColumnProps}>
                    {props.validationLineDetails.pattern}
                </Col>
            </Row>
            <Row className={"schematronDetails"}>
                <Col {...labelColumnProps}>
                    {props.intl.formatMessage(translationMessages.validation_labelColumnXPath)}:
                </Col>
                <Col {...valueColumnProps}>
                    <Button
                        className={"wrap_link_btn"}
                        onClick={() => {
                            props.setLineNumbers(props.validationLineDetails)
                        }}
                        type={"link"}
                    >
                        {props.validationLineDetails.xpath}
                    </Button>
                </Col>
            </Row>
            {/*<Button onClick={() => handleOpenDetailsDrawer(props.validationLineDetails.line)}>asd</Button>*/}
        </>
    );
};

export default injectIntl(SchematronDetails);
