import {Reducer} from "redux";

import LocaleUtility from "../../utils/localeUtility";
import {ConfigurationsActionTypesEnum, KnownAction} from "../actions/configuration";


export interface IConfigurationState {
    language: string;
}

const unloadedState: IConfigurationState = {
    language: LocaleUtility.getLocaleFromCookieOrDefault(),
};

export const reducer: Reducer<IConfigurationState, KnownAction> = (state: IConfigurationState = unloadedState, action: KnownAction) => {
    switch (action.type) {
        case ConfigurationsActionTypesEnum.SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case ConfigurationsActionTypesEnum.UNSET_LANGUAGE:
            return {
                ...state,
                language: LocaleUtility.getLocaleFromCookieOrDefault(),
            };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || unloadedState;
};
