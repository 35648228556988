import React, {useState} from 'react';
import {Icon, Upload} from "antd";
import {RcFile, UploadProps} from "antd/es/upload/interface";

import {convertRcFileToBase64, convertRcFileToText} from "../../utils/fileUploadUtility";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";

interface DocumentUploadOwnProps extends InjectedIntlProps {
    validateDocument: (base64data: string, fileText: string, documentName: string, localizedErrorMessage: string) => void;
    setIsDocumentUploading: (loading: boolean) => void;
    numberOfDocumentsStillLoading: number;
}

const translationMessages = defineMessages(
    {
        validation_documentUploadText: {
            id: "documentUpload.validation_documentUploadText",
            defaultMessage: "Click or drag file to this area to upload"
        },
        validation_documentUploadHint: {
            id: "documentUpload.validation_documentUploadHint",
            defaultMessage: "Upload XML files you wish to validate"
        },
        validation_errorMessage: {
            id: "documentUpload.validation_errorMessage",
            defaultMessage: "There was an error trying to validate document"
        },
    }
);

const DocumentUpload = function (props: DocumentUploadOwnProps) {
    const handleSubmit = (base64data: string, fileText: string, fileName: string) => {
        props.validateDocument(base64data, fileText, fileName, props.intl.formatMessage(translationMessages.validation_errorMessage));
    };

    const handleOnLoadEnd = (base64data: string, fileText: string, fileName: string) => {
        props.setIsDocumentUploading(false);
        handleSubmit(base64data, fileText, fileName);
    };

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]): boolean | PromiseLike<void> => {
        props.setIsDocumentUploading(true);

        const promises: [Promise<string>, Promise<string>] = [convertRcFileToBase64(file), convertRcFileToText(file)]

        Promise.all(promises)
            .then(results => {
                handleOnLoadEnd(results[0], results[1], file.name);
            })
            .catch(error => console.log(error))

        return false;
    };

    const uploadProps: UploadProps = {
        name: 'file',
        beforeUpload: handleBeforeUpload,
        multiple: true,
        showUploadList: false,
        disabled: props.numberOfDocumentsStillLoading > 0,
    };

    return (
        <div className={"container"}>
            <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox"/>
                </p>
                <p className="ant-upload-text">{props.intl.formatMessage(translationMessages.validation_documentUploadText)}</p>
                <p className="ant-upload-hint">
                    {props.intl.formatMessage(translationMessages.validation_documentUploadHint)}
                </p>
            </Upload.Dragger>
        </div>
    );
};

export default injectIntl(DocumentUpload);
