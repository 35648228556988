import React from 'react';

import {IConfigurationState} from "../../redux/reducers/configuration";
import * as ConfigurationAction from "../../redux/actions/configuration";
import {ApplicationState} from "../../store";
import {connect} from "react-redux";
import {Select} from "antd";

type LanguageSwitcherProps =
    IConfigurationState
    & typeof ConfigurationAction.actionCreators;

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => {
    const handleLanguageChange = (value: string) => {
        props.setLanguage(value)
    };

    return (
        <Select onChange={handleLanguageChange} value={props.language}>
            <Select.Option value={"is"}>
                IS
            </Select.Option>
            <Select.Option value={"en"}>
                EN
            </Select.Option>
        </Select>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    ...state.configuration,
});

const mapDispatchToProps = {
    ...ConfigurationAction.actionCreators,
};

const LanguageSwitcherRedux = connect<IConfigurationState, typeof ConfigurationAction.actionCreators, {}>(
    mapStateToProps,
    mapDispatchToProps
)(LanguageSwitcher);
export default LanguageSwitcherRedux;
