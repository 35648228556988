import {notification} from "antd";
import {ErrorMessageModel, ErrorModel} from "../models/errorModel";
import LogUtility from "./logUtility";

const privateMethods = {
    createErrorMessage(errorJson: ErrorMessageModel) {
        const errorMessageText = errorJson.hasOwnProperty("exceptionMessage") ? errorJson.exceptionMessage : errorJson.message;
        return errorJson.statusCode === "422" || errorJson.statusCode === "500"
            ? errorMessageText
            : errorJson.statusCode === "400"
                ? errorMessageText
                : `${errorMessageText} - Error Code: ${errorJson.statusCode} (${errorJson.statusText}) `;
    }
};

class ErrorMessageUtility {
    static dispatchError = (dispatch: any, dispatchType: any, response: any, documentName: string, fileText: string, requestId: string, errorNotificationMessageText: string) => {
        // LogUtility.log(response);
        if (typeof response.json === "function") {
            response.json()
                .then((errorJson: ErrorMessageModel) => {
                    const errorMessage = privateMethods.createErrorMessage(errorJson);
                    // notification.error({
                    //     message: `${errorNotificationMessageText} ${documentName}`,
                    //     description: `${errorMessage}`
                    // });
                    dispatch({
                        type: dispatchType,
                        documentName: documentName,
                        fileText: fileText,
                        requestId: requestId,
                        error: {status: errorJson.statusCode, message: errorMessage, localizedErrorMessage: errorNotificationMessageText},
                    });
                });
        } else {
            // notification.error({
            //     message: `${errorNotificationMessageText} ${documentName}`,
            //     description: `${response}`
            // });

            let status = "";
            let message = response;

            if (response.hasOwnProperty("errorCode") && response.hasOwnProperty("message")) {
                status = response.errorCode;
                message = response.message;
            }

            dispatch({
                type: dispatchType,
                documentName: documentName,
                requestId: requestId,
                error: {status, message, localizedErrorMessage: errorNotificationMessageText}
            });
        }

    };

    static errorNotificationToastr = (errorJson: ErrorMessageModel, errorTitleText: string = "") => {
        const errorMessage = privateMethods.createErrorMessage(errorJson);
        if (errorJson.statusCode === "400") {
            notification.warning({
                message: `${errorTitleText}`,
                description: `${errorMessage}`
            });
        } else {
            notification.error({
                message: `${errorTitleText}`,
                description: `${errorMessage}`
            });
        }
    }
}

export default ErrorMessageUtility;
