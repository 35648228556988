import * as React from "react";
import {connect} from "react-redux";
import {IntlProvider} from "react-intl";

import * as moment from "moment";
import "moment/locale/is";

import {ConfigProvider} from "antd";
import enUS from "antd/es/locale-provider/en_US";
import isIS from "antd/es/locale-provider/is_IS";
import {Locale} from "antd/es/locale-provider";


import {ApplicationState} from "./store";

import * as ConfigurationActions from "./redux/actions/configuration";
import {IConfigurationState} from "./redux/reducers/configuration";

import localeData from "./translations/locales";
import ConfigurationUtility from "./utils/configurationUtility";
import LocaleUtility from "./utils/localeUtility";

interface IHotSwappingIntlProviderOwnProps {
    initialLocale: string;
    initialMessages: any;
    languageString?: string;
}

type HotSwappingIntlProviderProps =
    IConfigurationState
    & typeof ConfigurationActions.actionCreators
    & IHotSwappingIntlProviderOwnProps;

const HotSwappingIntlProvider: React.FunctionComponent<HotSwappingIntlProviderProps> = (props) => {
    let antLocale: Locale;
    const {languageString} = props;
    const language = languageString ? languageString : props.language;
    const defaultUiLanguage = ConfigurationUtility.getConfigurationVariableWithLocalhostFallback("default-ui-language");

    switch (language && language.toLowerCase()) {
        case "en":
            antLocale = enUS;
            moment.locale("en");
            break;
        case "is":
            antLocale = isIS;
            moment.locale("is");
            break;
        default:
            antLocale = isIS;
            moment.locale("is");
    }
    return (
        <ConfigProvider locale={antLocale}>
            <IntlProvider
                locale={language}
                defaultLocale={defaultUiLanguage}
                key={language}
                messages={localeData[language]}
            >
                {props.children}
            </IntlProvider>
        </ConfigProvider>
    );
};

const HotSwappingIntlProviderRedux = connect<IConfigurationState, typeof ConfigurationActions.actionCreators, IHotSwappingIntlProviderOwnProps>(
    (state: ApplicationState) => state.configuration,
    ConfigurationActions.actionCreators
)(HotSwappingIntlProvider);
export default HotSwappingIntlProviderRedux;
