import * as React from "react";
import {Col, Row} from "antd";

export interface TableListWithLabelType {
    label: string | React.ReactNode;
    value: string | React.ReactNode;
    labelClass?: string;
    shouldRemoveLabelColon?: boolean;
    valueClass?: string;
}

export interface TableListWithLabelProps {
    data: TableListWithLabelType[];
    align?: "top" | "middle" | "bottom" | "stretch";
    justify?: "start" | "end" | "center" | "space-around" | "space-between";
    customClassName?: string;
    customStyle?: React.CSSProperties;
}

const TableListWithLabel: React.FC<TableListWithLabelProps> = (props) => {

    const tableRows = props.data.map((element, index) => (
        <tr key={index}>
            {element.label && <td className={element.labelClass} style={{fontWeight: 700, paddingRight: "0.5em", verticalAlign: "top", whiteSpace: "nowrap"}}>{element.label}{!element.shouldRemoveLabelColon && ":"}</td>}
            {element.value && <td className={element.valueClass} style={{wordBreak: "break-word"}}>{element.value}</td>}
        </tr>
    ))


    return (
        <Row
            align={props.align}
            justify={props.justify}
            className={props.customClassName}
            style={props.customStyle}
        >
            <Col span={24}>
                <table>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default TableListWithLabel;
