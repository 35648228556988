import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

import configureStore from "./store";

import "./index.css";

import * as serviceWorker from "./serviceWorker";

import {language, messages} from "./utils/localeUtility";

import App from "./App";
import HotSwappingIntlProvider from "./HotSwappingIntlProvider";
import UmzFooter from "./components/UmzFooter";
import ConfigurationUtility from "./utils/configurationUtility";

export const store = configureStore();

const branding = ConfigurationUtility.getConfigurationVariableWithLocalhostFallback("branding");

let footer: React.ReactElement;

switch (branding) {
    case "stadlar":
        footer = <UmzFooter/>;
        break;
    case "unimaze":
    default:
        footer = <></>;
        break;
}

ReactDOM.render(
    <Provider store={store}>
        <HotSwappingIntlProvider initialLocale={language} initialMessages={messages}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </HotSwappingIntlProvider>
    </Provider>,
    document.getElementById("root"));

ReactDOM.render(
    <Provider store={store}>
        <HotSwappingIntlProvider initialLocale={language} initialMessages={messages}>
            {footer}
        </HotSwappingIntlProvider>
    </Provider>
    , document.getElementById("umz-footer")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
