import * as React from 'react';
import {InjectedIntlProps, injectIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";
import euLogo from "../images/en_cef_300x42.png";

const translationMessages = defineMessages(
    {
        footer_poweredByUmz: {
            id: "footer.footer_poweredByUmz",
            defaultMessage: "Powered by Unimaze. Implemented as part of the ICELAND-INV18 project for Icelandic Standards."
        },
        footer_disclaimer: {
            id: "footer.footer_disclaimer",
            defaultMessage: "The contents of this web site is the sole responsibility of Icelandic Standards and does not necessary reflect the opinion of the European Union."
        },
    }
);

interface Props extends InjectedIntlProps {

}

const UmzFooter = (props: Props) => {
    return (
        <div className={"footer"}>
            <div id="umz-footer-left">
                    {props.intl.formatMessage(translationMessages.footer_poweredByUmz)}
                    <br/>
                    {props.intl.formatMessage(translationMessages.footer_disclaimer)}
            </div>
            <div id="umz-footer-right" style={{height: "100%"}}>
                <img src={euLogo} alt={"EU"} height={"auto"} style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    display: "block",
                }}/>
            </div>

        </div>
    );
};

// export default UmzFooter;
export default injectIntl(UmzFooter);
