export const getLineNumberFromXmlStringByXpath = (xmlString: string, xpath: string): number[] => {
    if (!xmlString || !xpath) {
        return [];
    }
    // Create a DOMParser
    const parser = new DOMParser();
    // Parse XML string into a DOM document
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");

    //Find node from XPath
    const singleNodeValue = document.evaluate(
        convertWildcardNamespaceToSpecificNamespaces(xpath, xmlDoc),
        xmlDoc,
        (prefix) => namespacePrefixToNamespace(prefix, xmlDoc),
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
    ).singleNodeValue;

    const openLineNumber = xmlNodeToOriginalLineNumber(singleNodeValue as HTMLElement, xmlDoc, xmlString);
    const closeLineNumber = xmlNodeToOriginalLineNumber(singleNodeValue as HTMLElement, xmlDoc, xmlString, true);
    // console.log("Line: " + line);

    const list = [];
    for (let i = openLineNumber; i <= closeLineNumber; i++) {
        list.push(i);
    }

    return list
}

const xmlNodeToOriginalLineNumber = (element: HTMLElement, xmlRootNode: Document, sContent: string, closeTag: boolean = false) => {
    const sTagName = element.tagName;
    const aNodeListByTag = xmlRootNode.getElementsByTagName(sTagName);
    let iMaxIndex = 0;
    for (let j = 0; j < aNodeListByTag.length; j++) {
        if (aNodeListByTag.item(j) === element) {
            iMaxIndex = j;
            break;
        }
    }
    const regex = closeTag
        ? new RegExp("<\/" + sTagName + "(>|(\\s.*>))", 'g')
        : new RegExp("<" + sTagName + "(>|((\\s.*)))", 'g');
    let offset = 0;
    for (let i = 0; i <= iMaxIndex; i++) {
        const regExpExecArray = regex.exec(sContent);
        offset = regExpExecArray.index;
    }
    let line = 0;
    for (let i = 0; i < sContent.substring(0, offset).length; i++) {
        if (sContent[i] === '\n') {
            line++;
        }
    }
    return line + 1;
}

const convertWildcardNamespaceToSpecificNamespaces = (xpath: string, xmlDoc: Document): string => {
    const elements = xpath.split("/");
    const reducer = (acc, curr) => {

        let replace = curr.replace("*", getNamespacePrefix(curr, xmlDoc));
        acc.push(replace);
        return acc
    };
    const a = elements.reduce(reducer, [])
    return a.join("/");
}

const getNamespacePrefix = (s: string, xmlDoc: Document): string => {
    const namespaceRegex: RegExp = /(?<=\[namespace-uri\(\)=').*(?=')/g
    const namespace = s.match(namespaceRegex)
    if (namespace  && namespace.length > 0) {
        return xmlDoc.lookupPrefix(namespace[0])
    } else {
        return null
    }
}

const namespacePrefixToNamespace = (prefix: string, xmlDoc: Document) => {
    if(prefix === "null"){
        prefix = null
    }
    return xmlDoc.lookupNamespaceURI(prefix)
};
