import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {ApplicationState} from "../store";

import * as ValidationActions from "../redux/actions/validations"
import {IValidationsState} from "../redux/reducers/validations";

import DocumentUpload from "./ValidateDocument/DocumentUpload";
import ValidationList from "./ValidateDocument/ValidationList";
import {Spin} from "antd";
import {DetailsDrawer} from "./DetailsDrawer";

type ValidateDocumentsProps =
    IValidationsState
    & typeof ValidationActions.actionCreators;

const ValidateDocument: React.FunctionComponent<ValidateDocumentsProps> = (props) => {
    const {validateDocument, validationOutput, activeKey, setActiveKey, setIsDocumentUploading, numberOfDocumentsStillLoading} = props;
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [highlightLineNumber, setHighlightLineNumber] = useState<number[]>()

    const validationOutputWithDocumentName = props.validationOutput.find(x => x.guid === activeKey)

    return (
        <>
            <div className={"list-container"}>
                <DocumentUpload
                    validateDocument={validateDocument}
                    setIsDocumentUploading={setIsDocumentUploading}
                    numberOfDocumentsStillLoading={numberOfDocumentsStillLoading}
                />
                <div style={{textAlign: "center"}}>
                    <Spin spinning={numberOfDocumentsStillLoading > 0}/>
                </div>
                {validationOutput.length > 0 && <ValidationList
                    validationOutput={validationOutput}
                    removeValidationResult={props.removeValidationResult}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    setDrawerVisible={setDrawerVisible}
                    setHighlightLineNumber={setHighlightLineNumber}
                    validationOutputWithDocumentName={validationOutputWithDocumentName}
                />}
            </div>
            <DetailsDrawer
                errorLineNumbers={highlightLineNumber}
                visible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                validationOutputWithDocumentName={validationOutputWithDocumentName}
            />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    ...state.validations,
});

const mapDispatchToProps = {
    ...ValidationActions.actionCreators,
};

const ValidateDocumentRedux = connect<IValidationsState,
    typeof ValidationActions.actionCreators,
    {}>(
    mapStateToProps,
    mapDispatchToProps
)(ValidateDocument);

export default ValidateDocumentRedux;
