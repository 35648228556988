// tslint:disable
/**
 * Tools Services v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * Contact: info@unimaze.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ValidationLineDetails
 */
export interface ValidationLineDetails {
    /**
     * 
     * @type {string}
     * @memberof ValidationLineDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationLineDetails
     */
    context?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationLineDetails
     */
    pattern?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationLineDetails
     */
    xpath?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationLineDetails
     */
    line?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationLineDetails
     */
    column?: number | null;
}
/**
 * 
 * @export
 * @interface ValidationLineOutput
 */
export interface ValidationLineOutput {
    /**
     * 
     * @type {string}
     * @memberof ValidationLineOutput
     */
    type?: ValidationLineOutputTypeEnum;
    /**
     * 
     * @type {ValidationLineDetails}
     * @memberof ValidationLineOutput
     */
    detail?: ValidationLineDetails;
    /**
     * 
     * @type {string}
     * @memberof ValidationLineOutput
     */
    group?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ValidationLineOutputTypeEnum {
    SchemaError = 'SchemaError',
    SchemaWarning = 'SchemaWarning',
    SchematronError = 'SchematronError',
    SchematronWarning = 'SchematronWarning'
}

/**
 * 
 * @export
 * @interface ValidationOutput
 */
export interface ValidationOutput {
    /**
     * 
     * @type {string}
     * @memberof ValidationOutput
     */
    transactionKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationOutput
     */
    profileId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationOutput
     */
    customizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationOutput
     */
    documentId?: string;
    /**
     * 
     * @type {Array<ValidationLineOutput>}
     * @memberof ValidationOutput
     */
    validationLines?: Array<ValidationLineOutput>;
}

/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDocument(options: any = {}): RequestArgs {
            const localVarPath = `/api/v1/documents/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDocument(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationOutput> {
            const localVarAxiosArgs = DocumentApiAxiosParamCreator(configuration).validateDocument(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDocument(options?: any) {
            return DocumentApiFp(configuration).validateDocument(options)(axios, basePath);
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public validateDocument(options?: any) {
        return DocumentApiFp(this.configuration).validateDocument(options)(this.axios, this.basePath);
    }

}


