import {Reducer} from "redux";

import {KnownAction, ValidationOutputWithDocumentName, ValidationsActionTypesEnum} from "../actions/validations";
import ReduxUtility from "../../utils/reduxUtility";
import {Simulate} from "react-dom/test-utils";
import {ErrorModel} from "../../models/errorModel";
import {IErrorsAndWarningsInfo} from "../../utils/validationUtility";


export interface IValidationsState {
    validationOutput: Array<ValidationOutputWithDocumentName>;
    activeKey: string;
    validationErrors: Array<{ documentName: string, error: ErrorModel }>;
    numberOfDocumentsStillLoading: number;
}

const unloadedState: IValidationsState = {
    validationOutput: [],
    activeKey: "",
    validationErrors: [],
    numberOfDocumentsStillLoading: 0,
};

export const reducer: Reducer<IValidationsState, KnownAction> = (state: IValidationsState = unloadedState, action: KnownAction) => {
    switch (action.type) {
        case ValidationsActionTypesEnum.VALIDATE_DOCUMENT:
            return {
                ...state,
                validationOutput: [action.validationOutputDocumentName, ...state.validationOutput]
            };
        case ValidationsActionTypesEnum.VALIDATE_DOCUMENT_SUCCESS:
            const indexForSuccess = ReduxUtility.findIndexOfObject([...state.validationOutput], "guid", action.validationOutputDocumentName.guid);
            return {
                ...state,
                validationOutput: ReduxUtility.updateAtIndex<ValidationOutputWithDocumentName>(state.validationOutput, indexForSuccess, action.validationOutputDocumentName),
            };
        case ValidationsActionTypesEnum.VALIDATE_DOCUMENT_FAILED:
            const indexForFailed = ReduxUtility.findIndexOfObject([...state.validationOutput], "guid", action.requestId);
            const invalidOutput: ValidationOutputWithDocumentName = {
                isValidating: false,
                guid: action.requestId,
                fileText: action.fileText,
                documentName: action.documentName,
                errorMessage: action.error.message,
                errorDescription: action.error.localizedErrorMessage,
                disabled: true,
            };

            return {
                ...state,
                validationOutput: ReduxUtility.updateAtIndex<ValidationOutputWithDocumentName>(state.validationOutput, indexForFailed, invalidOutput),
                validationErrors: [...state.validationErrors, {documentName: action.documentName, error: action.error}]
            };
        case ValidationsActionTypesEnum.REMOVE_VALIDATION_RESULT:
            const newValidationArray = [...state.validationOutput];
            newValidationArray.splice(action.index, 1);
            return {
                ...state,
                validationOutput: newValidationArray,
            };
        case ValidationsActionTypesEnum.SET_ACTIVE_KEY:
            return {
                ...state,
                activeKey: action.activeKey,
            };
        case ValidationsActionTypesEnum.SET_IS_DOCUMENT_UPLOADING:
            let numberOfDocumentsStillLoading = state.numberOfDocumentsStillLoading;
            if(action.isDocumentsUploading){
                numberOfDocumentsStillLoading++;
            } else {
                numberOfDocumentsStillLoading--
            }
            return {
                ...state,
                numberOfDocumentsStillLoading: numberOfDocumentsStillLoading,
            };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || unloadedState;
};
