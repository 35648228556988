import React from 'react';
import {Table} from "antd";

import {ValidationLineDetails, ValidationLineOutput, ValidationLineOutputTypeEnum} from "../../../../apiClients/tapi";
import {ColumnProps} from "antd/es/table";
import SchematronDetails from "./ValidationResultsList/SchematronDetails";
import SchemaDetails from "./ValidationResultsList/SchemaDetails";
import ValidationUtility from "../../../../utils/validationUtility";

import {InjectedIntlProps, injectIntl} from "react-intl";
import {defineMessages} from "react-intl.macro";
import {getLineNumberFromXmlStringByXpath} from "../../../../utils/xmlUtility";
import {ValidationOutputWithDocumentName} from "../../../../redux/actions/validations";

interface IValidationResultsListOwnProps extends InjectedIntlProps {
    validationLineOutput: Array<ValidationLineOutput>;
    setDrawerVisible: (drawerVisible: boolean) => void;
    setHighlightLineNumber: (highlightLineNumber: number[]) => void;
    validationOutputWithDocumentName: ValidationOutputWithDocumentName;
}

class ValidationResultsTable extends Table<ValidationLineOutput> {
}

const translationMessages = defineMessages(
    {
        validation_titleColumnType: {
            id: "validationResultsList.validation_titleColumnType",
            defaultMessage: "Type"
        },
        validation_titleColumnDetails: {
            id: "validationResultsList.validation_titleColumnDetails",
            defaultMessage: "Details"
        },
        validation_titleColumnRule: {
            id: "validationResultsList.validation_titleColumnRule",
            defaultMessage: "Rule"
        },
    }
);

const ValidationResultsList: React.FunctionComponent<IValidationResultsListOwnProps> = (props) => {
    const columns: ColumnProps<ValidationLineOutput>[] = [
        {
            key: "type",
            title: props.intl.formatMessage(translationMessages.validation_titleColumnType),
            dataIndex: "type",
            className: "validationLineIconColumn",
            width: 2,
            sorter: (a, b) => {
                const nameA = a.type.toString().toUpperCase();
                const nameB = b.type.toString().toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            },
            render: (text, record): React.ReactNode => {
                return (
                    <span className={"validationLineIconAndRuleColumn"}>
                            {ValidationUtility.getValidationIcon(record)}
                        <p>{record.group}</p>
                        </span>
                )
            }
        },
        {
            key: "details",
            title: props.intl.formatMessage(translationMessages.validation_titleColumnDetails),
            dataIndex: "details",
            className: "validationLineDetailsColumn",
            width: 9,
            render: (text, record): React.ReactNode => {
                switch (record.type) {
                    case ValidationLineOutputTypeEnum.SchemaError:
                    case ValidationLineOutputTypeEnum.SchemaWarning:
                        return <SchemaDetails
                            validationLineDetails={record.detail}
                            setLineNumbers={setLineNumbers}
                            // setDrawerVisible={props.setDrawerVisible}
                            // setHighlightLineNumber={props.setHighlightLineNumber}
                        />;
                    case ValidationLineOutputTypeEnum.SchematronError:
                    case ValidationLineOutputTypeEnum.SchematronWarning:
                        return <SchematronDetails
                            validationLineDetails={record.detail}
                            setLineNumbers={setLineNumbers}
                            // setDrawerVisible={props.setDrawerVisible}
                            // setHighlightLineNumber={props.setHighlightLineNumber}
                        />;
                    default:
                        return null;
                }
            }
        },
        // {
        //     key: "rule",
        //     title: props.intl.formatMessage(translationMessages.validation_titleColumnRule),
        //     dataIndex: "rule",
        //     className: "validationLineGroupColumn",
        //     width: 3,
        //     sorter: (a, b) => {
        //         const nameA = a.group.toString().toUpperCase();
        //         const nameB = b.group.toString().toUpperCase();
        //         if (nameA < nameB) {
        //             return -1;
        //         }
        //         if (nameA > nameB) {
        //             return 1;
        //         }
        //     },
        //     render: (text, record): React.ReactNode => {
        //         return record.group;
        //     }
        // }
    ];

    const setLineNumbers = (recordDetail: ValidationLineDetails) => {
        const {xpath, line} = recordDetail;
        const lineNumbers: number[] = line
            ? [line]
            : getLineNumberFromXmlStringByXpath(props.validationOutputWithDocumentName.fileText, xpath);
        props.setHighlightLineNumber(lineNumbers)
        props.setDrawerVisible(true)
    };

    return (<ValidationResultsTable
            dataSource={props.validationLineOutput}
            columns={columns}
            pagination={false}
            className={"validationResultsTable"}
            tableLayout={"fixed"}
            rowKey={(record, index) => (index.toString())}
            // onRow={(record, index) => ({
            //     onClick: setLineNumbers(record.detail)
            // })}
        />
    );
};

export default injectIntl(ValidationResultsList);
