import {Action} from "redux";
import {v4 as uuidv4} from "uuid";

import {AppThunkAction} from "../../store";

import {Configuration, DocumentApi, ValidationOutput} from "../../apiClients/tapi";

import ConfigurationUtility from "../../utils/configurationUtility";
import ApiUtility from "../../utils/apiUtility";
import ValidationUtility, {IErrorsAndWarningsInfo} from "../../utils/validationUtility";
import ErrorMessageUtility from "../../utils/errorMessageUtility";
import {ErrorModel} from "../../models/errorModel";

export enum ValidationsActionTypesEnum {
    VALIDATE_DOCUMENT = "VALIDATE_DOCUMENT",
    VALIDATE_DOCUMENT_SUCCESS = "VALIDATE_DOCUMENT_SUCCESS",
    VALIDATE_DOCUMENT_FAILED = "VALIDATE_DOCUMENT_FAILED",

    REMOVE_VALIDATION_RESULT = "REMOVE_VALIDATION_RESULT",

    SET_ACTIVE_KEY = "SET_ACTIVE_KEY",

    SET_IS_DOCUMENT_UPLOADING = "SET_IS_DOCUMENT_UPLOADING",
}

export interface ValidationOutputWithDocumentName extends ValidationOutput {
    isValidating: boolean;
    guid: string;
    fileText: string;
    documentName: string;
    errorMessage?: string | any;
    errorDescription?: any;
    validationSummary?: IErrorsAndWarningsInfo;
    disabled: boolean;
}

interface IValidateDocument extends Action<ValidationsActionTypesEnum.VALIDATE_DOCUMENT> {
    validationOutputDocumentName: ValidationOutputWithDocumentName;
}

interface IValidateDocumentSuccess extends Action<ValidationsActionTypesEnum.VALIDATE_DOCUMENT_SUCCESS> {
    validationOutputDocumentName: ValidationOutputWithDocumentName;
}

interface IValidateDocumentFailed extends Action<ValidationsActionTypesEnum.VALIDATE_DOCUMENT_FAILED> {
    documentName: string;
    requestId: string;
    fileText: string;
    error: ErrorModel;
}

interface IRemoveValidationResult extends Action<ValidationsActionTypesEnum.REMOVE_VALIDATION_RESULT> {
    index: number;
}

interface ISetActiveKey extends Action<ValidationsActionTypesEnum.SET_ACTIVE_KEY> {
    activeKey: string;
}

interface ISetIsDocumentUploading extends Action<ValidationsActionTypesEnum.SET_IS_DOCUMENT_UPLOADING>{
    isDocumentsUploading: boolean;
}

export type KnownAction = IValidateDocument
    | IValidateDocumentSuccess
    | IValidateDocumentFailed
    | IRemoveValidationResult
    | ISetActiveKey
    | ISetIsDocumentUploading;


export const actionCreators = {
    validateDocument: (base64data: string, fileText: string, documentName: string, localizedErrorMessage: string): AppThunkAction<KnownAction> => (dispatch: (action: KnownAction) => void) => {
        const requestId = uuidv4();
        const config = new Configuration({
            basePath: ConfigurationUtility.getConfigurationVariableWithLocalhostFallback("tapi-url")
        });
        const api = new DocumentApi(config);

        const options = ApiUtility.addBase64PayloadToPostRequest(base64data, requestId);

        api.validateDocument(options)
            .then(results => {

                const validationOutputDocumentName: ValidationOutputWithDocumentName = {
                    ...results.data,
                    isValidating: false,
                    guid: requestId,
                    fileText,
                    documentName: documentName,
                    disabled: false,
                };

                validationOutputDocumentName.validationSummary = ValidationUtility.getErrorsAndWarningsInfo(validationOutputDocumentName);

                dispatch({
                    type: ValidationsActionTypesEnum.VALIDATE_DOCUMENT_SUCCESS,
                    validationOutputDocumentName,
                })
            })
            .catch((error) => {
                //TODO: when backend starts returning the correct error message in "message" pass down error.response to the utility below
                ErrorMessageUtility.dispatchError(dispatch, ValidationsActionTypesEnum.VALIDATE_DOCUMENT_FAILED, error.response, documentName, fileText, requestId, localizedErrorMessage);
            });

        let validationOutputDocumentName: ValidationOutputWithDocumentName = {
            guid: requestId,
            fileText,
            isValidating: true,
            documentName: documentName,
            disabled: true,
        };
        validationOutputDocumentName.documentName = documentName;
        dispatch({
            type: ValidationsActionTypesEnum.VALIDATE_DOCUMENT,
            validationOutputDocumentName
        })
    },
    removeValidationResult: (index: number) => <IRemoveValidationResult>{
        type: ValidationsActionTypesEnum.REMOVE_VALIDATION_RESULT,
        index
    },
    setActiveKey: (activeKey: string | Array<string>) => <ISetActiveKey>{
        type: ValidationsActionTypesEnum.SET_ACTIVE_KEY,
        activeKey
    },
    setIsDocumentUploading: (isDocumentsUploading: boolean) => <ISetIsDocumentUploading>{
        type: ValidationsActionTypesEnum.SET_IS_DOCUMENT_UPLOADING,
        isDocumentsUploading: isDocumentsUploading,
    }
};
