import React from 'react';
import {Layout} from "antd";

import './css/site.css';

import {routes} from "./routes";
import StadlarHeader from "./common/StadlarHeader";
import LanguageSwitcher from "./components/Common/LanguageSwitcher";
import ConfigurationUtility from "./utils/configurationUtility";
import UnimazeHeader from "./common/UnimazeHeader";

const branding = ConfigurationUtility.getConfigurationVariableWithLocalhostFallback("branding");

let header: React.ReactElement;

switch (branding) {
    case "stadlar":
        header = <Layout.Header>
            <StadlarHeader/>
        </Layout.Header>;
        break;
    case "unimaze":
    default:
        header = <Layout.Header>
            <UnimazeHeader/>
        </Layout.Header>;
        break;
}

const App: React.FC = () => {
    return (
        <>
            <Layout
                style={{height: "100%", width: "100%"}}
            >
                {header}
                <Layout.Content>
                    <div className={"language_switcher_container"}>
                        <LanguageSwitcher/>
                    </div>
                    <div>
                        {routes}
                    </div>
                </Layout.Content>
            </Layout>
        </>
    );
};

export default App;
